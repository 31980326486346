import React, {useState, useEffect} from "react";

export default function ClassicoShortsBack({
  lightContrast,
  darkContrast,
  stitching,
  patchColor,
  azomo,
  printStyle,
  getDataFromChild,
  preview,
}) {
  function adjust(color, amount) {
    return (
      "#" +
      color
        .replace(/^#/, "")
        .replace(/../g, (color) =>
          (
            "0" + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)
          ).substr(-2)
        )
    );
  }

  const [darkenColor, setDarkenColor] = useState(darkContrast);

  const [lightContrastActive, setLightContrastActive] = useState(false);
  const [darkContrastActive, setDarkContrastActive] = useState(false);
  const [stitchingActive, setStitchingActive] = useState(false);
  const [patchActive, setPatchActive] = useState(false);
  const [azomoActive, setAzomoActive] = useState(false);

  const handleClick = (tabId) => {
    if (!preview) {
      getDataFromChild(tabId);
    }
  };

  useEffect(() => {
    setDarkenColor(adjust(lightContrast, -150));
  }, lightContrast);

  return (
    <svg
      className={printStyle}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 500 500"
      enableBackground="new 0 0 500 500"
    >
      <path
        fill={lightContrast}
        onClick={() => handleClick("base")}
        onMouseEnter={() => setLightContrastActive(true)}
        onMouseLeave={() => setLightContrastActive(false)}
        style={
          lightContrastActive && !preview
            ? {fill: adjust(lightContrast, 55)}
            : {fill: lightContrast}
        }
        d="M225.563,298.988c-1.612-5.216-3.39-10.522-5.446-16.22c-6.063-16.828-13.451-33.542-20.596-49.708
 c-2.851-6.45-5.8-13.118-8.618-19.691c-2.888-6.738-5.545-13.78-8.116-20.588c-4.706-12.468-9.568-25.364-16.113-37.112
 c-1.386-2.489-2.756-4.92-4.064-7.224c-0.872-1.526-1.748-3.052-2.629-4.574c-0.296-0.506-0.588-1.012-0.884-1.514l0.004-0.202
 c0.514-16.458,1.065-36.96,1.152-40.193v-0.078c0.008-0.21,0.012-0.337,0.012-0.358h-0.012V80.972c0-0.411-0.255-0.782-0.65-0.93
 c-7.762-2.484-15.615-4.64-23.213-6.721c-3.093-0.847-6.29-1.724-9.461-2.616C103.441,64.09,78.986,58.425,52.17,53.386
 c-2.291-0.428-2.904,0.518-3.287,2.542c-0.308,1.658-0.605,3.167-0.901,4.615l-0.362,1.773c-1,5.323-2.156,9.547-3.636,13.294
 c-0.062,0.157-0.07,0.284-0.053,0.387L40.373,83.6c-27.828,51.09-17.881,95.228-15.607,103.554c0.243,0.876,0.407,1.781,0.49,2.686
 c0.378,4.048,1.501,31.25,1.403,51.686c-0.016,3.081-0.062,6.31-0.144,9.872c-0.095,4.142-0.239,8.605-0.44,14.044
 c-0.123,3.184-0.251,6.367-0.383,9.552l-0.156,3.904c-0.156,3.908-0.321,8.062-0.436,12.139c-0.107,3.776-0.099,7.462-0.078,10.572
 c0.008,1.279,0.016,2.562,0.029,3.842c0.029,3.653,0.062,7.429,0.041,11.144l-0.041,7.651l0.292,0.045
 c0.51,0.079,51.514,7.919,98.671,10.552h0.67l0.033-0.301c0.782-6.652,2.719-15.072,3.18-17.013
 c4.41,2.16,6.084,10.415,6.647,13.192l0.111,0.49l0.313-0.042c0.469-0.057,47.338-5.866,94.014-16.783l0.794-0.209l-0.079-0.333
 C228.554,309.103,227.353,304.8,225.563,298.988z"
      />
      <path
        fill={lightContrast}
        onClick={() => handleClick("base")}
        onMouseEnter={() => setLightContrastActive(true)}
        onMouseLeave={() => setLightContrastActive(false)}
        style={
          lightContrastActive && !preview
            ? {fill: adjust(lightContrast, 55)}
            : {fill: lightContrast}
        }
        d="M477.572,311.762l-0.002-0.013c-0.009-2.456-0.023-5.271-0.042-8.421h0.02l-0.004-0.687h-0.021
 c-0.194-31.012-0.963-92.126-3.937-139.831c-3.052-49.021-9.185-70.691-12.612-79.304c0.313-0.991,0.341-2.493,0.341-2.859
 c0-0.588-0.004-14.385-0.415-17.339c-0.424-3.081-2.024-4.212-2.094-4.257l-0.079-0.058l-2.39-0.119l-0.021,0.337l-0.004-0.337
 c-80.715,1.58-158.333,0.996-175.154,0.749l-2.958-0.058c-0.839,0.016-1.608,0.452-2.11,1.197c-0.555,0.823-0.933,1.826-1.123,2.982
 c-0.465,2.879-0.736,16.372-0.745,16.944c-0.008,0.378-0.008,1.983,0.337,2.954c-3.352,8.733-9.313,30.489-12.348,79.168
 c-0.449,7.211-0.856,17.684-1.214,26.927c-0.189,4.895-0.371,9.519-0.539,13.213l-0.193,4.348
 c-0.44,10.103-0.831,20.642-1.164,31.32c-0.041,1.362-0.082,2.711-0.123,4.048l-0.074,2.554
 c-0.983,34.336-1.061,59.507-1.061,59.749l-0.444,17.125v0.766l0.292,0.053c15.034,2.621,30.609,3.488,44.628,3.488
 c27.079,0,48.354-3.233,48.679-3.283l1.464-0.251l0.037-0.255c0.309-2.16,0.469-6.75,0.666-12.563
 c0.391-11.197,0.921-26.532,2.966-34.113c2.184-8.095,3.636-21.242,4.504-29.099c0.362-3.246,0.621-5.594,0.773-6.162
 c0.123-0.444,0.189-1.9,0.313-4.895c0.374-8.992,1.25-30.053,6.142-32.168h0.07c4.895,2.114,5.771,23.176,6.145,32.168
 c0.073,1.758,0.125,2.974,0.18,3.778l-0.012-0.001l-0.052,1.03l0.176,0.009c0.005,0.024,0.01,0.058,0.015,0.078
 c0.157,0.568,0.415,2.912,0.774,6.162c0.867,7.853,2.324,21.004,4.508,29.099c2.016,7.47,2.254,22.435,2.427,33.357
 c0.099,5.965,0.173,10.679,0.485,12.908v0.659l0.292,0.053c14.936,2.604,30.465,3.468,44.496,3.468
 c27.375,0,49.041-3.291,49.366-3.34l0.852-0.128v-0.304c0-0.187,0.006-4.135-0.019-10.845
 C477.57,311.762,477.572,311.762,477.572,311.762z"
      />
      <polygon
        fill="#E2E2E2"
        points="474.789,203.062 467.24,203.033 467.24,201.804 474.739,201.804 "
      />
      <path
        fill="#E2E2E2"
        d="M265.839,201.68l-0.041,1.23l-4.656-0.14c0.016-0.407,0.037-0.819,0.053-1.246L265.839,201.68z"
      />
      <polygon
        fill="#E2E2E2"
        points="457.779,106.678 380.77,110.274 380.729,109.323 457.746,105.909 "
      />
      <polygon
        fill="#E2E2E2"
        points="355.324,109.323 355.283,110.274 278.27,106.679 278.302,105.909 "
      />
      <path
        fill={darkContrast}
        onClick={() => handleClick("contrast")}
        onMouseEnter={() => setDarkContrastActive(true)}
        onMouseLeave={() => setDarkContrastActive(false)}
        style={
          darkContrastActive && !preview
            ? {fill: adjust(darkContrast, 55)}
            : {fill: darkContrast}
        }
        d="M475.986,244.712l-0.938-0.004c-0.518-0.584-2.67-2.275-4.755-3.916c-1.39-1.094-2.826-2.221-3.171-2.563
 l0.119-34.512l7.573,0.025c0.481,13.163,0.852,26.429,1.131,39.087l-0.276,0.337l0.016,0.012c0.053,0.045,0.103,0.086,0.14,0.119
 l0.016,0.012l0.111-0.128C475.965,243.691,475.973,244.202,475.986,244.712"
      />
      <path
        fill={darkContrast}
        onClick={() => handleClick("contrast")}
        onMouseEnter={() => setDarkContrastActive(true)}
        onMouseLeave={() => setDarkContrastActive(false)}
        style={
          darkContrastActive && !preview
            ? {fill: adjust(darkContrast, 55)}
            : {fill: darkContrast}
        }
        d="M265.777,203.589l-1.057,34.5c-0.358,0.329-1.835,1.411-3.262,2.456c-0.589,0.432-1.197,0.88-1.781,1.312
 c0.033-1.061,0.061-2.123,0.098-3.2c0.329-10.679,0.72-21.213,1.16-31.316l0.177-3.891L265.777,203.589z"
      />
      <path
        fill={darkContrast}
        onClick={() => handleClick("contrast")}
        onMouseEnter={() => setDarkContrastActive(true)}
        onMouseLeave={() => setDarkContrastActive(false)}
        style={
          darkContrastActive && !preview
            ? {fill: adjust(darkContrast, 55)}
            : {fill: darkContrast}
        }
        d="M458.944,131.137c-0.44,0.387-1.386,1.374-3.694,3.789c-1.584,1.654-3.254,3.406-3.677,3.854l-61.53,2.855
 c-0.745-0.613-5.603-4.714-7.47-6.294V135.3l-0.046,0.004c-0.275-0.234-0.481-0.407-0.592-0.502l-1.136-23.842l77.014-3.595
 L458.944,131.137z"
      />
      <path
        fill={darkContrast}
        onClick={() => handleClick("contrast")}
        onMouseEnter={() => setDarkContrastActive(true)}
        onMouseLeave={() => setDarkContrastActive(false)}
        style={
          darkContrastActive && !preview
            ? {fill: adjust(darkContrast, 55)}
            : {fill: darkContrast}
        }
        d="M355.25,110.961l-1.131,23.838c-0.115,0.099-0.325,0.276-0.596,0.506l-0.041-0.004l-0.005,0.041
 c-1.875,1.588-6.725,5.681-7.47,6.294l-61.525-2.855c-0.428-0.448-2.098-2.201-3.677-3.854c-2.312-2.415-3.258-3.402-3.694-3.789
 l1.127-23.772L355.25,110.961z"
      />
      <polygon
        fill="#E2E2E2"
        points="132.804,215.95 132.64,217.41 71.27,210.327 71.41,209.105 "
      />
      <path
        fill="#E2E2E2"
        d="M66.54,107.608l-0.094,0.946l-35.134-3.529c0.103-0.28,0.21-0.559,0.317-0.839L66.54,107.608z"
      />
      <path
        fill={darkContrast}
        onClick={() => handleClick("contrast")}
        onMouseEnter={() => setDarkContrastActive(true)}
        onMouseLeave={() => setDarkContrastActive(false)}
        style={
          darkContrastActive && !preview
            ? {fill: adjust(darkContrast, 55)}
            : {fill: darkContrast}
        }
        d="M66.379,109.237l-2.406,23.747c-0.119,0.09-0.333,0.255-0.621,0.469l-0.041-0.004l-0.004,0.041
 c-1.958,1.485-7.018,5.315-7.795,5.882l-32.18-3.221c1.357-9.375,3.768-19.638,7.738-30.461L66.379,109.237z"
      />
      <path
        fill={darkContrast}
        onClick={() => handleClick("contrast")}
        onMouseEnter={() => setDarkContrastActive(true)}
        onMouseLeave={() => setDarkContrastActive(false)}
        style={
          darkContrastActive && !preview
            ? {fill: adjust(darkContrast, 55)}
            : {fill: darkContrast}
        }
        d="M132.566,218.085l-3.949,34.438c-0.28,0.3-1.51,1.296-2.703,2.258c-1.67,1.349-3.394,2.744-3.887,3.307
 l-48.326-5.557c-0.415-0.646-2.164-2.534-3.854-4.36c-1.135-1.23-2.312-2.501-2.583-2.871l3.933-34.298L132.566,218.085z"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        strokeDasharray="1.3291,1.3278"
        x1="64.607"
        y1="266.042"
        x2="66.745"
        y2="246.873"
      />
      <polyline
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        strokeDasharray="1.3291,1.3278"
        points="
 75.183,60.103 70.765,59.23 51.092,55.512 "
      />
      <polyline
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        strokeDasharray="1.3291,1.3278"
        points="
 126.653,72.734 112.512,68.707 104.234,66.668 87.108,62.723 "
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        strokeDasharray="1.3291,1.3278"
        x1="151.821"
        y1="79.829"
        x2="135.559"
        y2="75.27"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        strokeDasharray="1.3291,1.3278"
        d="
 M70.043,78.314l-2.157-0.37c0,0-8.997-1.439-21.113-3.479"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        strokeDasharray="1.3291,1.3278"
        d="
 M124.59,90.204l-13.997-3.742l-8.542-1.995c0,0-10.427-2.622-20.474-4.296"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        strokeDasharray="1.3291,1.3278"
        x1="150.715"
        y1="97.473"
        x2="132.759"
        y2="92.388"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        strokeDasharray="1.3291,1.3278"
        d="
 M25.915,187.349l22.424,2.261c1.803-1.785,5.297-5.253,6.891-6.84l4.241-41.706c-0.166,0.125-0.325,0.244-0.474,0.357
 c-0.409,0.308-0.75,0.564-0.991,0.743c-0.124,0.093-0.229,0.171-0.304,0.226c-0.754,0.555-1.572,0.793-2.432,0.709
 c-0.049-0.005-0.097-0.011-0.146-0.018l-31.92-3.196"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        strokeDasharray="1.3291,1.3278"
        d="
 M26.172,189.43l22.921,2.311c1.563-1.543,6.754-6.695,8.099-8.038l4.79-47.098c-0.689,0.521-1.221,0.924-1.237,0.935
 c-1.083,0.818-2.164,1.635-2.977,2.246c-0.408,0.306-0.747,0.561-0.986,0.739c-0.12,0.09-0.217,0.162-0.285,0.211
 c-0.145,0.108-0.514,0.376-1.027,0.326c-0.03-0.003-0.059-0.007-0.088-0.012l-32.071-3.211"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        strokeDasharray="1.3291,1.3278"
        d="
 M23.956,133.159l30.377,3.042c1.383-1.04,6.475-5.154,6.475-5.154l1.933-19.118l-32.461-3.259"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        strokeDasharray="1.3291,1.3278"
        d="
 M23.816,135.2l31.412,3.145c1.196-0.893,4.791-3.612,7.129-5.384l0.676-0.566l2.255-22.267l-35.071-3.521"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        strokeDasharray="1.3291,1.3278"
        x1="56.821"
        y1="157.566"
        x2="22.126"
        y2="154.165"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        d="M117.447,90.823
 c-0.045,0.216-0.091,0.433-0.137,0.65"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        x1="117.035"
        y1="92.76"
        x2="117.034"
        y2="92.763"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        strokeDasharray="1.3213,1.3159"
        d="
 M117.034,92.763c-5.868,27.128-16.809,58.395-31.673,70.046c0,0-1.439,1.43-3.319,1.825"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        d="M82.041,164.634
 c-0.214,0.045-0.434,0.077-0.659,0.091"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        strokeDasharray="1.3291,1.3278"
        x1="82.687"
        y1="161.369"
        x2="86.092"
        y2="161.369"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        d="M126.281,95.858
 c0.097-0.18,0.19-0.387,0.274-0.605"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        x1="126.953"
        y1="94.024"
        x2="126.954"
        y2="94.02"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        strokeDasharray="1.2962,1.2908"
        d="
 M126.954,94.02c0.121-0.445,0.19-0.771,0.19-0.771s2.905-14.031,3.103-17.017c0.072-1.08-0.051-1.945-0.238-2.61"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        d="M130.01,73.622
 c-0.065-0.234-0.139-0.443-0.214-0.627"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        d="M128.275,96.619
 c0.097-0.18,0.189-0.387,0.274-0.605"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        x1="128.946"
        y1="94.785"
        x2="128.947"
        y2="94.781"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        strokeDasharray="1.2962,1.2908"
        d="
 M128.947,94.781c0.121-0.445,0.19-0.771,0.19-0.771s2.905-14.031,3.103-17.017c0.072-1.079-0.051-1.945-0.238-2.61"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        d="M132.003,74.383
 c-0.065-0.234-0.139-0.443-0.214-0.627"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        d="M76.436,83.697
 c-0.041-0.204-0.063-0.429-0.072-0.661"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        x1="76.42"
        y1="81.723"
        x2="76.42"
        y2="81.718"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        strokeDasharray="1.32,1.3147"
        d="M76.421,81.718
 c0.05-0.466,0.111-0.8,0.111-0.8s2.753-14.258,3.877-17.087c0.416-1.047,0.987-1.776,1.54-2.278"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        d="M81.948,61.554
 c0.176-0.16,0.351-0.297,0.518-0.415"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        d="M74.236,83.536
 c-0.043-0.204-0.068-0.429-0.079-0.66"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        strokeDasharray="0.0041,0"
        x1="74.203"
        y1="81.53"
        x2="74.203"
        y2="81.526"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        strokeDasharray="1.3525,1.3468,0.0041,0"
        d="
 M74.203,81.526c0.049-0.485,0.112-0.834,0.112-0.834s2.766-14.592,3.919-17.485c0.43-1.08,1.026-1.827,1.604-2.338"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        d="M79.837,60.87
 c0.178-0.158,0.354-0.293,0.523-0.408"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        strokeDasharray="1.3291,1.3278"
        d="
 M154.967,80.54c-0.631,0.827-1.201,1.527-2.011,2.734c-0.951,1.418-1.72,2.688-1.953,4.408c-0.249,1.839,0.053,3.754,0.899,5.433
 c0.901,1.79,1.842,3.574,2.759,5.367l3.55,0.91"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        strokeDasharray="1.3291,1.3278"
        x1="154.967"
        y1="80.54"
        x2="158.781"
        y2="81.567"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        x1="39.696"
        y1="263.263"
        x2="39.769"
        y2="262.603"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        x1="39.915"
        y1="261.3"
        x2="39.915"
        y2="261.296"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        strokeDasharray="1.3167,1.3114"
        x1="39.915"
        y1="261.296"
        x2="44.582"
        y2="219.443"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        x1="44.582"
        y1="219.444"
        x2="44.656"
        y2="218.783"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        x1="35.005"
        y1="305.326"
        x2="35.078"
        y2="304.666"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        x1="35.224"
        y1="303.356"
        x2="35.225"
        y2="303.352"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        strokeDasharray="1.3229,1.3176"
        x1="35.225"
        y1="303.352"
        x2="39.328"
        y2="266.559"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        x1="39.328"
        y1="266.559"
        x2="39.402"
        y2="265.898"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        x1="58.062"
        y1="307.898"
        x2="58.135"
        y2="307.237"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        x1="58.281"
        y1="305.928"
        x2="58.282"
        y2="305.924"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        strokeDasharray="1.3229,1.3176"
        x1="58.282"
        y1="305.924"
        x2="62.385"
        y2="269.13"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        x1="62.385"
        y1="269.13"
        x2="62.459"
        y2="268.47"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        x1="46.533"
        y1="306.612"
        x2="46.607"
        y2="305.952"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        x1="46.753"
        y1="304.642"
        x2="46.753"
        y2="304.638"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        strokeDasharray="1.3229,1.3176"
        x1="46.753"
        y1="304.638"
        x2="50.856"
        y2="267.844"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        x1="50.857"
        y1="267.844"
        x2="50.93"
        y2="267.184"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        strokeDasharray="1.3291,1.3278"
        d="
 M125.188,259.636c-1.455,13.896-3.195,28.656-4.749,41.966c-0.615,5.263-1.114,9.547-1.442,12.447l-4.895-0.585"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        strokeDasharray="1.3291,1.3278"
        x1="66.607"
        y1="239.672"
        x2="43.698"
        y2="237.117"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        strokeDasharray="1.3291,1.3278"
        x1="67.123"
        y1="235.039"
        x2="44.215"
        y2="232.485"
      />
      <polyline
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.3073"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        points="
 45.792,218.347 43.783,218.975 45.706,218.985 43.65,220.033 45.573,220.044 43.541,220.88 45.463,220.891 43.433,221.727 
 45.354,221.739 43.324,222.573 45.245,222.585 43.215,223.42 45.136,223.432 43.106,224.265 "
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        strokeDasharray="1.3291,1.3278"
        d="
 M73.106,211.652l-3.831,33.442c0.489,0.556,4.7,5.113,5.416,5.953l46.588,5.352c0.732-0.639,5.117-4.206,5.831-4.825l3.811-33.258"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        strokeDasharray="1.3291,1.3278"
        x1="129.68"
        y1="222.615"
        x2="73.07"
        y2="216.302"
      />
      <polyline
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        strokeDasharray="1.3291,1.3278"
        points="
 83.806,264.779 77.401,264.065 72.208,310.64 "
      />
      <polyline
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        strokeDasharray="1.3291,1.3278"
        points="
 110.283,267.732 116.425,268.417 111.231,314.992 "
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        strokeDasharray="1.3291,1.3278"
        d="
 M108.587,261.932l-3.483,31.237c-0.029,0.259-0.277,0.444-0.554,0.413l-21.316-2.377c-0.277-0.031-0.478-0.267-0.449-0.525
 l3.483-31.237"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        strokeDasharray="1.3291,1.3278"
        x1="82.505"
        y1="293.585"
        x2="80.554"
        y2="311.073"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        strokeDasharray="1.3291,1.3278"
        x1="104.784"
        y1="296.028"
        x2="102.834"
        y2="313.516"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        x1="39.129"
        y1="275.144"
        x2="39.789"
        y2="275.217"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        strokeDasharray="0.0041,0"
        x1="41.14"
        y1="275.368"
        x2="41.144"
        y2="275.368"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        strokeDasharray="1.364,1.3587,0.0041,0"
        x1="41.144"
        y1="275.368"
        x2="60.115"
        y2="277.484"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        x1="60.115"
        y1="277.484"
        x2="60.776"
        y2="277.558"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        x1="38.832"
        y1="277.81"
        x2="39.492"
        y2="277.884"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        strokeDasharray="0.0041,0"
        x1="40.842"
        y1="278.034"
        x2="40.846"
        y2="278.035"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        strokeDasharray="1.364,1.3587,0.0041,0"
        x1="40.846"
        y1="278.035"
        x2="59.818"
        y2="280.151"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        x1="59.818"
        y1="280.151"
        x2="60.478"
        y2="280.224"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        d="M26.212,314.644c0,0,0.224,0.033,0.656,0.096"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        x1="28.171"
        y1="314.927"
        x2="28.174"
        y2="314.928"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        strokeDasharray="1.3225,1.3171"
        d="
 M28.174,314.928c9.791,1.399,54.594,7.587,97.283,9.97"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        d="M125.461,324.898
 c0.221,0.012,0.442,0.025,0.663,0.037"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        d="M132.469,321.91c0,0,0.224-0.031,0.656-0.093"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        strokeDasharray="0.0041,0"
        x1="134.441"
        y1="321.63"
        x2="134.444"
        y2="321.629"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        strokeDasharray="1.3348,1.3295,0.0041,0"
        d="
 M134.444,321.629c9.351-1.35,50.449-7.509,91.772-17.242"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        d="M226.22,304.386
 c0.216-0.051,0.431-0.102,0.647-0.153"
      />
      <path
        fill="#010101"
        d="M229.661,313.856c-1.143-4.739-2.344-9.042-4.134-14.854c-1.612-5.216-3.39-10.522-5.446-16.22
 c-6.063-16.828-13.451-33.542-20.596-49.708c-2.851-6.45-5.8-13.118-8.618-19.691c-2.888-6.738-5.545-13.78-8.116-20.588
 c-4.706-12.468-9.568-25.364-16.113-37.112c-1.386-2.489-2.756-4.92-4.064-7.224c-0.872-1.526-1.748-3.052-2.629-4.574
 c-0.296-0.506-0.588-1.012-0.884-1.514l0.004-0.202c0.514-16.458,1.065-36.96,1.152-40.193v-0.078
 c0.008-0.21,0.012-0.337,0.012-0.358h-0.012V80.986c0-0.411-0.255-0.782-0.65-0.93c-7.762-2.484-15.615-4.64-23.213-6.721
 c-3.093-0.847-6.29-1.724-9.461-2.616C103.405,64.104,78.95,58.439,52.134,53.4c-2.291-0.428-2.904,0.518-3.287,2.542
 c-0.309,1.658-0.605,3.167-0.901,4.615l-0.362,1.773c-1,5.323-2.155,9.547-3.636,13.294c-0.062,0.157-0.07,0.284-0.053,0.387
 l-3.558,7.602c-27.828,51.09-17.881,95.228-15.607,103.554c0.243,0.876,0.407,1.781,0.49,2.686
 c0.378,4.048,1.501,31.25,1.403,51.686c-0.016,3.081-0.062,6.31-0.144,9.872c-0.095,4.142-0.239,8.605-0.44,14.044
 c-0.123,3.184-0.251,6.367-0.383,9.552l-0.156,3.904c-0.156,3.908-0.321,8.062-0.436,12.139c-0.107,3.776-0.099,7.462-0.078,10.572
 c0.008,1.279,0.016,2.562,0.029,3.842c0.029,3.653,0.062,7.429,0.041,11.144l-0.041,7.651l0.292,0.045
 c0.51,0.079,51.514,7.919,98.671,10.552h0.671l0.033-0.301c0.782-6.652,2.719-15.072,3.18-17.013
 c4.41,2.16,6.084,10.415,6.647,13.192l0.111,0.49l0.313-0.042c0.469-0.057,47.338-5.866,94.014-16.783l0.794-0.209L229.661,313.856z
  M159.341,80.694c0.119,0.045,0.197,0.165,0.197,0.292v20.099c-0.876-0.243-3.052-0.839-6.125-1.678
 c-0.576-1.144-1.164-2.283-1.752-3.419c-0.555-1.069-1.111-2.143-1.65-3.216c-0.802-1.588-1.107-3.447-0.868-5.233
 c0.226-1.661,0.995-2.916,1.9-4.265c0.642-0.955,1.139-1.6,1.625-2.23c0.436-0.568,0.889-1.152,1.448-1.966
 C155.861,79.603,157.605,80.138,159.341,80.694 M136.173,73.988c5.66,1.555,11.468,3.147,17.26,4.879
 c-0.494,0.712-0.905,1.246-1.304,1.761c-0.49,0.637-0.995,1.292-1.65,2.267c-0.954,1.423-1.765,2.748-2.007,4.554
 c-0.263,1.921,0.07,3.92,0.93,5.631c0.543,1.073,1.098,2.147,1.654,3.221c0.494,0.954,0.987,1.909,1.473,2.867
 c-5.097-1.386-12.328-3.336-20.177-5.405c0.629-3.069,2.768-13.649,2.941-16.24c0.128-1.88-0.325-3.204-0.712-3.97
 C135.112,73.701,135.647,73.844,136.173,73.988 M133.635,73.293c0.239,0.325,1.144,1.728,0.979,4.183
 c-0.193,2.929-3.069,16.829-3.093,16.968c-0.21,0.975-0.658,2.427-1.053,2.851l-6.327-1.308c-0.066-0.016-0.099-0.065-0.107-0.086
 c-0.012-0.024-0.021-0.07,0.004-0.115c0.522-0.967,0.88-2.629,0.897-2.703c0.119-0.572,2.912-14.089,3.11-17.063
 c0.136-2.036-0.374-3.624-0.745-4.483C129.415,72.129,131.541,72.717,133.635,73.293 M126.428,71.294
 c0.239,0.444,1.098,2.23,0.938,4.681c-0.16,2.41-2.139,12.262-2.847,15.742c-5.849-1.518-11.193-2.871-15.891-4.027l-5.636-1.32
 c-5.829-1.374-11.312-2.559-14.673-3.167c-1.65-0.3-3.303-0.601-4.957-0.893l-2.382-0.423c0.452-2.312,2.785-14.052,3.772-16.545
 c1.053-2.645,3.385-3.735,3.406-3.743l-0.012-0.029C101.315,64.577,114.01,67.802,126.428,71.294 M113.319,89.558
 c-2.847,13.488-13.044,55.857-31.296,70.872c1.024-33.114,2.801-45.496,9.169-75.977c3.209,0.65,7.33,1.564,11.645,2.58l5.636,1.32
 C110.015,88.731,111.636,89.134,113.319,89.558 M87.214,61.356c-0.847,0.547-2.291,1.719-3.089,3.735
 c-1.119,2.814-3.776,16.482-3.887,17.067c-0.016,0.07-0.325,1.761-0.099,2.896c-0.012,0.033-0.144,0.115-0.341,0.074l-8.963-1.752
 c-0.383-0.572-0.267-2.234-0.095-3.192c0.029-0.148,2.777-14.665,3.908-17.507c1.078-2.699,3.303-3.316,3.398-3.34l-0.004-0.016
 C81.126,59.986,84.183,60.665,87.214,61.356 M48.251,62.458l0.362-1.765c0.296-1.452,0.592-2.966,0.905-4.628
 c0.346-1.843,0.716-2.329,2.489-1.995c8.531,1.6,16.82,3.266,24.916,5.006c-0.876,0.477-2.151,1.456-2.904,3.348
 c-1.148,2.871-3.776,16.721-3.941,17.602l-0.814-0.14c-0.099-0.016-0.403-0.078-0.712-0.14c-0.321-0.065-0.642-0.131-0.745-0.148
 c-1.662-0.284-3.32-0.568-4.977-0.847c-1.555-0.264-3.106-0.522-4.661-0.786c-1.403-0.234-2.805-0.465-4.208-0.699
 c-1.209-0.202-2.419-0.399-3.628-0.597c-0.975-0.16-1.95-0.316-2.921-0.473l-0.518-0.082c-0.551-0.091-1.102-0.177-1.654-0.259
 c-0.206-0.029-0.411-0.058-0.621-0.082C46.1,72.014,47.255,67.777,48.251,62.458 M31.628,104.186l34.911,3.422l-0.095,0.947
 l-35.134-3.529C31.414,104.745,31.521,104.466,31.628,104.186 M31.069,105.691l35.31,3.545l-2.406,23.748
 c-0.119,0.09-0.333,0.255-0.621,0.469l-0.041-0.004l-0.004,0.041c-1.958,1.485-7.018,5.315-7.795,5.882l-32.18-3.221
 C24.689,126.777,27.099,116.514,31.069,105.691 M25.898,189.788c-0.09-0.942-0.259-1.884-0.51-2.797
 c-1.415-5.171-5.796-24.212-2.16-50.161l32.262,3.23c0.021,0.012,0.041,0.016,0.07,0.016c0.165,0,0.469-0.23,1.608-1.086
 c0.814-0.609,1.892-1.427,2.974-2.246c0.029-0.021,1.785-1.349,3.069-2.32l-5.06,49.737c-0.802,0.81-7.713,7.671-8.692,8.626
 l-23.513-2.369C25.931,190.179,25.915,189.965,25.898,189.788 M25.701,323.674l0.037-7.063c0.016-3.719-0.012-7.499-0.045-11.151
 c-0.008-1.283-0.021-2.563-0.029-3.842c-0.021-3.102-0.029-6.779,0.078-10.551c0.115-4.068,0.28-8.223,0.436-12.131l0.16-3.9
 c0.128-3.183,0.259-6.368,0.378-9.551c0.206-5.442,0.346-9.91,0.44-14.056c0.082-3.567,0.132-6.8,0.144-9.885
 c0.086-18.095-0.819-43.196-1.304-50.432l23.554,2.374l0.09,0.013l0.086-0.041c0.136-0.062,9.054-8.939,9.087-9.046l0.008-0.032
 l5.138-50.523c0.337-0.255,0.555-0.423,0.572-0.436l0.099-0.09l2.501-24.71l0.16-1.629l-26.31-2.583h-0.045
 c-0.132-0.005-0.263-0.025-0.395-0.037c-0.074-0.012-0.148-0.008-0.222-0.016c-0.053-0.004-0.107-0.013-0.156-0.029v0.005
 l-8.281-0.811c2.443-6.371,5.426-12.924,9.062-19.601l3.496-7.478c0.234,0.024,0.469,0.058,0.699,0.091
 c0.551,0.078,1.094,0.169,1.641,0.255l0.518,0.082c0.436,0.07,0.868,0.14,1.3,0.21c0,0.004-0.004,0.004-0.004,0.008
 c0.123,0.012,0.243,0.025,0.362,0.041c0.09,0.008,0.177,0.021,0.263,0.037c0.041,0.008,0.082,0.02,0.119,0.033
 c0.012,0,0.021,0.004,0.029,0.008c0.288,0.045,0.568,0.09,0.852,0.136c1.209,0.197,2.419,0.399,3.628,0.596
 c1.403,0.234,2.805,0.464,4.208,0.699c1.551,0.263,3.106,0.522,4.656,0.786c1.658,0.28,3.32,0.564,4.977,0.847
 c0.103,0.017,0.415,0.079,0.724,0.144c0.317,0.066,0.629,0.128,0.732,0.144l0.831,0.144c-0.111,0.926-0.202,2.563,0.424,3.217
 l0.074,0.078l9.194,1.798c0.078,0.016,0.156,0.025,0.234,0.025c0.271,0,0.531-0.095,0.703-0.271
 c0.173-0.169,0.243-0.399,0.197-0.633c-0.148-0.749-0.021-1.851,0.053-2.357l2.386,0.424c1.65,0.292,3.303,0.588,4.957,0.888
 c0.687,0.128,1.473,0.276,2.324,0.449c-6.421,30.753-8.182,43.106-9.206,76.832l-2.468,48.099l-8.042-0.897l-0.251,2.238
 l0.041,0.004c-0.033,0.057-0.058,0.119-0.066,0.189l-1.045,9.144l-26.442-2.949l-0.041,0.341
 c-1.719,14.554-5.636,50.646-7.972,72.209c-0.905,8.355-1.6,14.751-1.884,17.297l-0.037,0.341l36.919,3.945
 c-0.222,1.369-0.592,3.681-1,6.219c-0.765,4.751-1.625,10.116-1.909,11.793C44.829,326.554,28.086,324.037,25.701,323.674
  M81.422,292.412c0.424,0.423,0.975,0.699,1.592,0.765l21.316,2.378c0.103,0.012,0.206,0.016,0.304,0.016
 c0.609,0,1.168-0.206,1.613-0.547l-2.147,19.259l-19.399-2.295l-5.397-0.576L81.422,292.412z M81.377,290.524l3.628-32.534
 l25.134,2.801l-3.628,32.534c-0.107,0.975-1.053,1.674-2.106,1.555l-21.316-2.378c-0.514-0.058-0.975-0.296-1.3-0.675
 C81.472,291.454,81.324,290.989,81.377,290.524 M107.054,293.955h-0.004c0.066-0.177,0.115-0.358,0.14-0.551l3.007-26.993
 l7.573,0.843l-5.339,47.873l0.679,0.074l5.413-48.548l-8.248-0.921l0.617-5.541l-26.487-2.954l-0.621,5.541l-8.248-0.917
 l-5.413,48.547l0.675,0.074l5.339-47.873l7.573,0.847l-3.011,26.989c-0.041,0.374,0.008,0.74,0.14,1.082l-2.213,19.811
 l-18.503-1.974l4.739-42.505l-26.355-2.941c1.937-17.771,3.998-36.483,5.142-46.178l25.763,2.871l-2.83,24.669
 c-0.016,0.123,0.016,0.251,0.082,0.353c0.222,0.334,1.164,1.358,2.686,2.999c1.555,1.682,3.492,3.776,3.801,4.299
 c0.09,0.148,0.247,0.251,0.42,0.271l48.441,5.57c0.025,0,0.045,0.004,0.07,0.004c0.165,0,0.325-0.074,0.428-0.206
 c0.374-0.465,2.291-2.016,3.834-3.262c0.304-0.243,0.58-0.469,0.839-0.679c-1.728,15.348-3.677,32.48-5.113,45.047
 c-0.897,7.861-1.584,13.899-1.876,16.507l-15.417-1.822L107.054,293.955z M59.44,309.289l-25.57-2.731
 c0.304-2.744,0.975-8.939,1.843-16.931c0.777-7.174,1.732-15.96,2.719-25.023l25.672,2.859L59.44,309.289z M125.914,254.782
 c-1.67,1.349-3.394,2.744-3.887,3.307l-48.326-5.557c-0.415-0.646-2.164-2.534-3.854-4.361c-1.135-1.23-2.312-2.501-2.583-2.871
 l3.933-34.298l61.37,7.083l-3.949,34.438C128.337,252.823,127.107,253.819,125.914,254.782 M71.27,210.326l0.14-1.221l61.394,6.845
 l-0.165,1.46L71.27,210.326z M124.042,334.176h-0.045c-19.338-1.081-39.317-3.035-56.199-4.973
 c0.296-1.724,1.148-7.042,1.905-11.765c0.411-2.559,0.786-4.891,1.004-6.253l13.912,1.485l36.182,4.278l0.062-0.551
 c0.28-2.534,0.979-8.642,1.888-16.643c1.456-12.76,3.447-30.222,5.191-45.746c0.658-0.543,1.078-0.905,1.226-1.082
 c0.066-0.078,0.107-0.176,0.119-0.275l3.97-34.632c0.008-0.066,0-0.136-0.021-0.202l0.041,0.004l0.28-2.472l-54.298-6.055
 l0.271,0.016l2.46-47.976c18.704-14.813,29.099-57.922,31.987-71.613c3.246,0.814,6.726,1.708,10.407,2.662
 c-0.07,0.341-0.111,0.543-0.115,0.559c-0.004,0.016-0.354,1.641-0.831,2.517c-0.123,0.226-0.128,0.494-0.021,0.728
 c0.111,0.239,0.329,0.411,0.584,0.465l6.631,1.37l0.115-0.078c0.798-0.522,1.337-2.888,1.436-3.361
 c0.008-0.025,0.016-0.082,0.033-0.157c13.653,3.599,25.434,6.841,27.306,7.355l-1.156,40.394c-0.3,9.729-0.588,18.038-0.757,20.51
 c-0.029,0.415-2.908,42.024-6.351,60.428l-3.381,13.25c-0.35,1.353-1.497,5.582-3.085,11.431
 c-3.069,11.308-7.705,28.396-11.468,42.826c-5.784,22.163-6.339,25.825-6.076,26.54
 C126.918,318.562,124.886,327.241,124.042,334.176 M135.161,330.45c-0.617-3.044-2.423-11.617-7.301-13.677
 c-0.197-3.188,12.254-49.062,17.606-68.791c1.592-5.854,2.74-10.082,3.085-11.443l2.777-10.868c0.337,3.64,1.641,12.18,6.29,31.55
 l0.021,0.058c10.86,24.833,17.433,64.767,17.807,67.083C153.668,328.076,137.592,330.141,135.161,330.45 M228.777,313.72
 c-18.482,4.323-36.997,7.844-52.645,10.522c-0.387-2.407-6.968-42.328-17.84-67.215c-7.018-29.243-6.376-33.599-6.347-33.764
 l-0.185-0.045l0.185,0.037c3.447-18.437,6.335-60.107,6.364-60.522c0.16-2.349,0.428-9.955,0.712-19.046
 c0.107,0.181,0.214,0.367,0.321,0.547c0.88,1.522,1.752,3.044,2.624,4.571c1.308,2.303,2.678,4.731,4.06,7.219
 c6.52,11.703,11.378,24.574,16.072,37.018c2.575,6.816,5.232,13.863,8.128,20.617c2.818,6.573,5.767,13.246,8.618,19.7
 c7.141,16.154,14.525,32.859,20.58,49.658c2.053,5.689,3.83,10.983,5.434,16.191c1.748,5.66,2.929,9.885,4.044,14.48L228.777,313.72
 z"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.3402"
        strokeMiterlimit="10"
        strokeDasharray="1.0202,1.0202"
        d="
 M374.331,240.61c0,0,1.639-32.691,1.242-36.382c-0.397-3.692-5.4-6.051-7.732-5.355h-0.101c-2.332-0.696-7.335,1.663-7.732,5.355
 c-0.397,3.692,1.242,36.382,1.242,36.382"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.3402"
        strokeMiterlimit="10"
        strokeDasharray="1.0202,1.0202"
        d="
 M362.25,221.1v-13.813c0-1.775,0.661-3.506,1.914-4.763c0.862-0.865,1.944-1.333,3.509-1.35"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.3402"
        strokeMiterlimit="10"
        strokeDasharray="1.0202,1.0202"
        d="
 M373.429,221.222l-0.192-14.005c0-1.775-0.661-3.506-1.914-4.763c-0.862-0.865-1.944-1.333-3.509-1.349"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.3402"
        strokeMiterlimit="10"
        strokeDasharray="1.023,1.0218"
        x1="366.551"
        y1="82.678"
        x2="366.551"
        y2="196.162"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.3402"
        strokeMiterlimit="10"
        strokeDasharray="1.023,1.0218"
        x1="365.283"
        y1="82.678"
        x2="365.283"
        y2="196.462"
      />
      <polyline
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        strokeDasharray="1.3291,1.3278"
        points="
 339.669,61.506 289.058,61.506 284.826,61.439 "
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        strokeDasharray="1.3291,1.3278"
        x1="376.523"
        y1="61.526"
        x2="357.692"
        y2="61.645"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        strokeDasharray="1.3291,1.3278"
        d="
 M340.065,79.313c-3.274-0.06-6.135-0.121-9.409-0.182c-4.98-0.093-9.96-0.185-14.94-0.277c-4.582-0.085-9.165-0.17-13.747-0.255
 c-3.357-0.063-6.713-0.125-10.069-0.187c-1.303-0.024-2.606-0.049-3.909-0.072l-5.37-0.241"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        strokeDasharray="1.3291,1.3278"
        d="
 M376.371,79.856c-0.426-0.003-0.851-0.006-1.276-0.007c-3.801-0.006-7.603-0.035-11.404-0.087c-1.502-0.02-4.238-0.045-5.74-0.073"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        strokeDasharray="1.3291,1.3278"
        d="
 M452.509,78.099c-4.525,0-4.396,0.204-4.463,0.206c-2.012,0.046-4.024,0.093-6.035,0.14c-4.09,0.095-8.181,0.19-12.271,0.285
 c-5.191,0.121-10.381,0.241-15.572,0.362c-5.314,0.123-10.628,0.247-15.942,0.371c-1.392,0.033-2.786,0.057-4.182,0.085"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        strokeDasharray="1.3291,1.3278"
        x1="394.017"
        y1="61.446"
        x2="451.069"
        y2="61.234"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        strokeDasharray="1.3291,1.3278"
        d="
 M394.522,192.181c-1.706-1.879-5.009-5.528-6.516-7.198l-2.008-41.873c0.159,0.134,0.311,0.262,0.455,0.382
 c0.392,0.33,0.719,0.603,0.95,0.795c0.119,0.1,0.22,0.183,0.292,0.241c0.724,0.594,1.528,0.876,2.391,0.838
 c0.049-0.002,0.098-0.005,0.146-0.01l61.667-2.86l1.365-0.072l0.928-0.992c0.082-0.087,0.791-0.832,1.744-1.829l2.016,42.095
 c-0.584,0.764-1.451,1.896-2.351,3.071l-0.975,1.274c-1.276,1.666-2.096,2.735-2.629,3.432L394.522,192.181z"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        strokeDasharray="1.3291,1.3278"
        d="
 M393.656,194.269c-1.478-1.624-6.387-7.046-7.658-8.459l-2.267-47.287c0.66,0.557,1.17,0.987,1.185,1
 c1.037,0.875,2.074,1.748,2.853,2.401c0.39,0.328,0.715,0.6,0.944,0.791c0.115,0.096,0.208,0.173,0.274,0.226
 c0.139,0.115,0.493,0.403,1.008,0.38c0.03-0.001,0.059-0.003,0.088-0.007l61.703-2.862l0.547-0.028l0.369-0.395
 c0.1-0.107,2.895-3.04,5.048-5.283l2.28,47.599c-0.508,0.667-1.626,2.125-2.797,3.655l-0.981,1.28
 c-1.888,2.463-2.775,3.622-3.208,4.192L393.656,194.269z"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        strokeDasharray="1.3291,1.3278"
        d="
 M391.389,138.528c-1.325-1.111-6.191-5.492-6.191-5.492l-0.909-19.194l70.937-3.31l0.921,19.378
 c-0.06,0.061-0.121,0.124-0.183,0.189c-0.685,0.707-1.585,1.645-2.606,2.712c-1.009,1.053-2.09,2.186-2.842,2.974L391.389,138.528z"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        strokeDasharray="1.3291,1.3278"
        d="
 M390.381,140.622c-1.147-0.956-4.591-3.863-6.831-5.757l-0.645-0.601l-1.063-22.356l75.023-3.5l1.06,22.308
 c-0.208,0.212-0.48,0.492-0.799,0.821c-0.682,0.704-1.58,1.639-2.597,2.702c-1.303,1.362-2.729,2.857-3.404,3.566L390.381,140.622z"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        strokeDasharray="1.3291,1.3278"
        x1="387.764"
        y1="159.73"
        x2="455.846"
        y2="156.714"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        strokeDasharray="1.3291,1.3278"
        d="
 M341.528,192.181c1.705-1.879,5.009-5.528,6.515-7.198l2.008-41.873c-0.159,0.134-0.311,0.262-0.455,0.382
 c-0.392,0.33-0.719,0.603-0.95,0.795c-0.119,0.1-0.22,0.183-0.292,0.241c-0.724,0.594-1.527,0.876-2.39,0.838
 c-0.049-0.002-0.097-0.005-0.146-0.01l-61.667-2.86l-1.365-0.072l-0.928-0.992c-0.082-0.087-0.791-0.832-1.744-1.829l-2.016,42.095
 c0.584,0.764,1.451,1.896,2.351,3.071l0.975,1.274c1.276,1.666,2.096,2.735,2.629,3.432L341.528,192.181z"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        strokeDasharray="1.3291,1.3278"
        d="
 M342.394,194.269c1.479-1.624,6.387-7.046,7.658-8.459l2.268-47.287c-0.66,0.557-1.17,0.987-1.185,1
 c-1.037,0.875-2.074,1.748-2.853,2.401c-0.39,0.328-0.715,0.6-0.944,0.791c-0.115,0.096-0.208,0.173-0.274,0.226
 c-0.139,0.115-0.493,0.403-1.009,0.38c-0.029-0.001-0.059-0.003-0.088-0.007l-61.703-2.862l-0.548-0.028l-0.369-0.395
 c-0.1-0.107-2.894-3.04-5.047-5.283l-2.279,47.599c0.508,0.667,1.625,2.125,2.796,3.655l0.981,1.28
 c1.887,2.463,2.775,3.622,3.209,4.192L342.394,194.269z"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        strokeDasharray="1.3291,1.3278"
        d="
 M344.661,138.528c1.325-1.111,6.191-5.492,6.191-5.492l0.909-19.194l-70.937-3.31l-0.921,19.378
 c0.059,0.061,0.121,0.124,0.183,0.189c0.685,0.707,1.585,1.645,2.606,2.712c1.008,1.053,2.09,2.186,2.841,2.974L344.661,138.528z"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        strokeDasharray="1.3291,1.3278"
        d="
 M345.669,140.622c1.147-0.956,4.591-3.863,6.831-5.757l0.645-0.601l1.063-22.356l-75.023-3.5l-1.06,22.308
 c0.208,0.212,0.48,0.492,0.799,0.821c0.682,0.704,1.58,1.639,2.597,2.702c1.304,1.362,2.73,2.857,3.404,3.566L345.669,140.622z"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        strokeDasharray="1.3291,1.3278"
        x1="348.286"
        y1="159.73"
        x2="280.204"
        y2="156.714"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        d="M352.319,311.555c0,0-0.224,0.035-0.656,0.097"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        x1="350.363"
        y1="311.835"
        x2="350.36"
        y2="311.836"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        strokeDasharray="1.3192,1.3139"
        d="
 M350.36,311.836c-9.604,1.314-52.829,6.523-91.983-0.167"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        d="M258.373,311.668
 c-0.218-0.037-0.437-0.075-0.655-0.113"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        d="M477.502,311.555c0,0-0.224,0.035-0.656,0.097"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        x1="475.545"
        y1="311.835"
        x2="475.543"
        y2="311.836"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        strokeDasharray="1.3192,1.3139"
        d="
 M475.543,311.836c-9.604,1.314-52.829,6.523-91.983-0.167"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        d="M383.555,311.668
 c-0.218-0.037-0.437-0.075-0.655-0.113"
      />
      <polyline
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        strokeDasharray="1.3291,1.3278"
        points="
 258.762,300.316 289.661,301.368 292.594,215.184 "
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        strokeDasharray="1.3291,1.3278"
        x1="266.276"
        y1="232.626"
        x2="290.51"
        y2="233.45"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        strokeDasharray="1.3291,1.3278"
        x1="266.434"
        y1="227.967"
        x2="290.668"
        y2="228.792"
      />
      <polyline
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.3073"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        points="
 291.425,214.585 293.514,215.502 291.419,215.228 293.493,216.569 291.4,216.295 293.48,217.422 291.386,217.149 293.467,218.276 
 291.374,218.004 293.454,219.13 291.361,218.858 293.441,219.983 291.348,219.711 293.427,220.835 "
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        strokeDasharray="1.3291,1.3278"
        d="
 M263.561,203.95l-1.033,33.645c-0.258,0.2-1.331,0.993-2.559,1.902"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        strokeDasharray="1.3291,1.3278"
        x1="261.1"
        y1="208.494"
        x2="262.877"
        y2="208.554"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        strokeDasharray="1.3291,1.3278"
        x1="264.982"
        y1="240.398"
        x2="262.989"
        y2="298.989"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        strokeDasharray="1.3291,1.3278"
        d="
 M455.863,83.061c0.306-1.016,0.316-2.73,0.316-2.73s0-14.329-0.411-17.293c-0.201-1.447-0.671-2.441-1.099-3.078"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        strokeDasharray="1.3291,1.3278"
        d="
 M457.698,83.061c0.306-1.016,0.316-2.73,0.316-2.73s0-14.329-0.411-17.293c-0.201-1.447-0.671-2.441-1.099-3.078"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        strokeDasharray="1.3291,1.3278"
        d="
 M280.918,60.977c-0.495,0.634-1.073,1.66-1.318,3.17c-0.469,2.886-0.744,16.894-0.744,16.894s-0.017,1.209,0.154,2.188"
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        strokeDasharray="1.3291,1.3278"
        d="
 M279.02,60.928c-0.495,0.634-1.073,1.66-1.318,3.17c-0.468,2.886-0.744,16.894-0.744,16.894s-0.017,1.209,0.154,2.188"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        strokeDasharray="1.3291,1.3278"
        x1="347.241"
        y1="61.473"
        x2="347.241"
        y2="83.49"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        strokeDasharray="1.3291,1.3278"
        x1="350.12"
        y1="61.473"
        x2="350.12"
        y2="83.49"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        strokeDasharray="1.3291,1.3278"
        x1="383.493"
        y1="61.473"
        x2="383.493"
        y2="83.49"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        strokeDasharray="1.3291,1.3278"
        x1="386.441"
        y1="61.473"
        x2="386.441"
        y2="83.49"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        x1="439.22"
        y1="259.144"
        x2="439.22"
        y2="258.48"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        x1="439.22"
        y1="257.169"
        x2="439.22"
        y2="257.165"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        strokeDasharray="1.3167,1.3114"
        x1="439.22"
        y1="257.165"
        x2="439.22"
        y2="215.053"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        x1="439.22"
        y1="215.053"
        x2="439.22"
        y2="214.389"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        x1="439.22"
        y1="301.468"
        x2="439.22"
        y2="300.804"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        x1="439.22"
        y1="299.486"
        x2="439.22"
        y2="299.482"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        strokeDasharray="1.3229,1.3176"
        x1="439.22"
        y1="299.482"
        x2="439.22"
        y2="262.46"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        x1="439.22"
        y1="262.46"
        x2="439.22"
        y2="261.796"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        x1="464.728"
        y1="301.468"
        x2="464.728"
        y2="300.804"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        x1="464.728"
        y1="299.486"
        x2="464.728"
        y2="299.482"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        strokeDasharray="1.3229,1.3176"
        x1="464.728"
        y1="299.482"
        x2="464.728"
        y2="262.46"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        x1="464.728"
        y1="262.46"
        x2="464.728"
        y2="261.796"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        x1="451.974"
        y1="301.468"
        x2="451.974"
        y2="300.804"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        x1="451.974"
        y1="299.486"
        x2="451.974"
        y2="299.482"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        strokeDasharray="1.3229,1.3176"
        x1="451.974"
        y1="299.482"
        x2="451.974"
        y2="262.46"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        x1="451.974"
        y1="262.46"
        x2="451.974"
        y2="261.796"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        strokeDasharray="1.3291,1.3278"
        x1="465.752"
        y1="232.716"
        x2="440.408"
        y2="232.716"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        strokeDasharray="1.3291,1.3278"
        x1="465.752"
        y1="228.054"
        x2="440.408"
        y2="228.054"
      />
      <polyline
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.3073"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        points="
 440.407,213.829 438.289,214.676 440.391,214.473 438.273,215.742 440.375,215.54 438.257,216.596 440.359,216.394 438.241,217.449 
 440.343,217.248 438.226,218.303 440.327,218.102 438.21,219.156 440.311,218.956 438.194,220.009 "
      />
      <path
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        strokeDasharray="1.3291,1.3278"
        d="
 M469.44,204.148l-0.112,33.661c0.575,0.476,5.306,4.203,6.5,5.195"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        strokeDasharray="1.3291,1.3278"
        x1="474.668"
        y1="208.774"
        x2="469.967"
        y2="208.774"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        x1="440.048"
        y1="271.014"
        x2="440.713"
        y2="271.014"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        strokeDasharray="0.0041,0"
        x1="442.039"
        y1="271.014"
        x2="442.043"
        y2="271.014"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        strokeDasharray="1.3311,1.3258,0.0041,0"
        x1="442.043"
        y1="271.014"
        x2="463.333"
        y2="271.014"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        x1="463.333"
        y1="271.014"
        x2="463.997"
        y2="271.014"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        x1="440.048"
        y1="273.697"
        x2="440.713"
        y2="273.697"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        strokeDasharray="0.0041,0"
        x1="442.039"
        y1="273.697"
        x2="442.043"
        y2="273.697"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        strokeDasharray="1.3311,1.3258,0.0041,0"
        x1="442.043"
        y1="273.697"
        x2="463.333"
        y2="273.697"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        x1="463.333"
        y1="273.697"
        x2="463.997"
        y2="273.697"
      />
      <line
        fill="none"
        stroke={stitching}
        onClick={() => handleClick("stitching")}
        onMouseEnter={() => setStitchingActive(true)}
        onMouseLeave={() => setStitchingActive(false)}
        style={
          stitchingActive && !preview
            ? {stroke: adjust(stitching, 55)}
            : {stroke: stitching}
        }
        strokeWidth="0.4476"
        strokeMiterlimit="10"
        strokeDasharray="1.3291,1.3278"
        x1="466.758"
        y1="239.433"
        x2="466.758"
        y2="259.144"
      />
      <path
        fill="#010101"
        d="M356.04,108.665l-38.222-1.691c-0.056,0.003-0.112,0.003-0.171,0.001c-0.154-0.005-0.307-0.03-0.461-0.031
 c-0.018,0-0.034-0.008-0.051-0.01l0,0.007l-39.486-1.748l-0.078,1.715l-1.181,24.879l0.3-0.132c0.016,0.021,0.041,0.042,0.062,0.062
 l-2.439,51.003c-0.021,0.152-0.021,0.152,3.826,5.175c1.707,2.23,3.636,4.747,3.866,5.06l0.066,0.169l60.966,2.867l0.083-0.041
 c0.136-0.07,8.56-9.408,8.589-9.515l0.008-0.033l2.435-50.728c0.32-0.271,0.53-0.452,0.547-0.469l0.091-0.091l1.181-24.809h-0.004
 L356.04,108.665z M351.038,186.213c-0.761,0.856-7.294,8.075-8.219,9.083l-60.329-2.842c-0.28-0.374-1.131-1.481-3.805-4.977
 c-1.567-2.04-3.323-4.332-3.685-4.813l2.406-50.275c1.9,1.954,6.298,6.565,6.664,6.96l0.094,0.103l61.859,2.871
 c0.016,0.008,0.037,0.012,0.062,0.012c0.16,0,0.448-0.239,1.559-1.172c0.777-0.654,1.814-1.526,2.85-2.398
 c0.025-0.025,1.712-1.444,2.937-2.485L351.038,186.213z M354.119,134.799c-0.115,0.099-0.325,0.276-0.596,0.506l-0.042-0.004
 l-0.004,0.041c-1.875,1.588-6.725,5.681-7.47,6.294l-61.525-2.855c-0.428-0.448-2.098-2.201-3.678-3.854
 c-2.312-2.415-3.257-3.402-3.694-3.789l1.127-23.772l77.014,3.595L354.119,134.799z M355.283,110.274l-77.014-3.595l0.033-0.769
 l77.022,3.414L355.283,110.274z"
      />
      <path
        fill="#010101"
        d="M477.492,303.341h0.02l-0.004-0.687h-0.021c-0.194-31.012-0.963-92.126-3.937-139.831
 c-3.052-49.021-9.186-70.691-12.612-79.304c0.313-0.991,0.341-2.493,0.341-2.859c0-0.588-0.004-14.385-0.415-17.338
 c-0.424-3.081-2.024-4.212-2.094-4.257l-0.079-0.058l-2.39-0.119l-0.021,0.337l-0.004-0.337
 c-80.715,1.58-158.333,0.996-175.154,0.749l-2.958-0.058c-0.839,0.016-1.608,0.452-2.11,1.197c-0.555,0.823-0.933,1.826-1.123,2.982
 c-0.465,2.879-0.736,16.372-0.745,16.944c-0.008,0.378-0.008,1.983,0.337,2.954c-3.353,8.733-9.313,30.489-12.348,79.168
 c-0.449,7.211-0.856,17.684-1.213,26.927c-0.189,4.895-0.371,9.519-0.539,13.213l-0.193,4.348
 c-0.44,10.103-0.831,20.642-1.164,31.32c-0.041,1.362-0.082,2.711-0.123,4.048l-0.074,2.555
 c-0.983,34.335-1.061,59.507-1.061,59.749l-0.444,17.125v0.766l0.292,0.053c15.034,2.621,30.609,3.488,44.628,3.488
 c27.079,0,48.354-3.233,48.679-3.283l1.464-0.251l0.037-0.255c0.309-2.16,0.469-6.75,0.666-12.563
 c0.391-11.197,0.921-26.532,2.966-34.113c2.184-8.095,3.636-21.242,4.504-29.099c0.362-3.246,0.621-5.594,0.773-6.162
 c0.123-0.444,0.189-1.9,0.313-4.895c0.374-8.992,1.25-30.053,6.142-32.168h0.07c4.895,2.114,5.771,23.176,6.145,32.168
 c0.124,2.995,0.189,4.451,0.309,4.895c0.157,0.568,0.415,2.912,0.774,6.162c0.868,7.853,2.324,21.004,4.508,29.099
 c2.016,7.47,2.254,22.435,2.427,33.357c0.099,5.965,0.173,10.679,0.485,12.908v0.659l0.292,0.053
 c14.936,2.604,30.465,3.468,44.496,3.468c27.375,0,49.041-3.291,49.366-3.34l0.851-0.128v-0.304
 C477.553,322.366,477.565,315.11,477.492,303.341 M476.784,302.654l-9.659,0.041v-42.768h-29.16
 c-0.046-17.873-0.074-36.705-0.004-46.466h28.564l-0.086,24.833c0,0.128,0.05,0.251,0.14,0.35c0.28,0.309,1.436,1.218,3.295,2.682
 c1.909,1.501,4.282,3.365,4.686,3.854c0.107,0.132,0.271,0.21,0.448,0.21l0.995,0.004
 C476.483,268.002,476.693,288.475,476.784,302.654 M466.438,260.614V302.7l-28.342,0.119c0-2.773-0.025-9.112-0.049-17.047
 c-0.025-7.215-0.053-16.047-0.078-25.158H466.438z M475.945,242.827l-0.276,0.337l0.016,0.012c0.054,0.045,0.103,0.086,0.14,0.119
 l0.017,0.012l0.111-0.128c0.013,0.51,0.021,1.02,0.033,1.53l-0.938-0.004c-0.518-0.584-2.669-2.275-4.755-3.916
 c-1.39-1.094-2.826-2.221-3.172-2.563l0.119-34.512l7.573,0.025C475.295,216.904,475.665,230.17,475.945,242.827 M474.789,203.062
 l-7.548-0.028v-1.23h7.499L474.789,203.062z M456.278,59.566l2.176,0.111c0.288,0.246,1.407,1.328,1.74,3.735
 c0.403,2.908,0.407,17.104,0.407,17.244c-0.004,0.999-0.148,2.509-0.448,3.007h-6.466c-0.066,0-0.103-0.041-0.119-0.062
 c-0.016-0.025-0.037-0.062-0.02-0.115c0.316-1.049,0.329-2.752,0.329-2.826c0-0.588-0.004-14.385-0.415-17.338
 c-0.26-1.896-0.967-3.052-1.489-3.673C453.406,59.624,454.842,59.595,456.278,59.566 M393.867,60.414
 c18.248-0.148,37.582-0.387,57.17-0.749c0.26,0.218,1.411,1.296,1.752,3.747c0.337,2.436,0.395,12.781,0.407,16.166l-3.163,0.062
 c-17.853,0.679-37.264,1.164-56.166,1.403V60.414z M377.368,60.525c5.163-0.024,10.44-0.062,15.813-0.107v23.525
 c0,0.399-0.325,0.724-0.724,0.724h-14.364c-0.399,0-0.724-0.325-0.724-0.724V60.525z M341.169,60.628
 c5.064,0,10.342-0.004,15.813-0.021v23.336c0,0.399-0.325,0.724-0.724,0.724h-14.364c-0.399,0-0.724-0.325-0.724-0.724V60.628z
  M284.938,60.364c10.016,0.115,29.876,0.263,55.479,0.263h0.066v20.276c-23.344-0.309-41.822-0.868-53.698-1.263l-4.483-0.053
 c0.082-3.571,0.333-13.204,0.707-15.508C283.387,61.738,284.612,60.623,284.938,60.364 M275.608,63.869
 c0.169-1.061,0.51-1.975,1.012-2.715c0.378-0.555,0.946-0.884,1.547-0.897l2.752,0.054c0.058,0.004,0.123,0.004,0.186,0.004h0.004
 c0.815,0.012,1.769,0.025,2.859,0.037c-0.568,0.633-1.333,1.785-1.629,3.616c-0.465,2.879-0.736,16.372-0.749,16.948
 c0,0.074-0.021,1.892,0.321,2.933l-6.644-0.189l-0.008-0.004c-0.292-0.494-0.404-1.97-0.391-2.942
 C274.872,80.574,275.147,66.699,275.608,63.869 M261.194,201.524l4.644,0.156l-0.041,1.23l-4.657-0.14
 C261.157,202.363,261.178,201.951,261.194,201.524 M259.775,238.656c0.329-10.679,0.72-21.213,1.16-31.316l0.177-3.891l4.665,0.14
 l-1.058,34.5c-0.358,0.329-1.834,1.411-3.262,2.456c-0.589,0.432-1.197,0.88-1.781,1.312
 C259.71,240.795,259.738,239.734,259.775,238.656 M259.578,245.254l0.074-2.534c0.703-0.522,1.473-1.086,2.209-1.625
 c1.913-1.403,3.098-2.274,3.39-2.571c0.09-0.095,0.144-0.218,0.148-0.35l0.761-24.821l28.548,0.971
 c-0.404,14.866-1.744,50.753-2.547,72.262c-0.296,7.935-0.534,14.27-0.629,17.042l-33.007-1.263
 C258.574,295.061,258.776,273.193,259.578,245.254 M367.434,203.037c-5.195,2.538-6.043,22.904-6.45,32.727
 c-0.103,2.431-0.181,4.352-0.288,4.739c-0.115,0.42-0.268,1.592-0.469,3.361c-0.432-8.984-1.736-36.853-1.345-40.485
 c0.255-2.365,2.13-3.941,3.225-4.669c1.782-1.185,3.925-1.769,5.327-1.473V203.037z M367.434,109.887v86.659
 c-1.584-0.276-3.809,0.337-5.705,1.6c-2.061,1.37-3.312,3.2-3.522,5.158c-0.469,4.373,1.399,41.785,1.477,43.373l0.23-0.008
 c-0.004,0.033-0.008,0.07-0.012,0.107c-0.863,7.832-2.316,20.946-4.488,28.992c-2.065,7.655-2.596,23.04-2.986,34.27
 c-0.193,5.57-0.346,10.016-0.625,12.234l-0.954,0.16c-0.481,0.079-48.893,7.437-92.776-0.152l0.441-17.285
 c0-0.042,0-0.712,0.008-1.942l33.674,1.288l0.012-0.341c0.086-2.563,0.333-9.108,0.641-17.392
 c0.81-21.674,2.164-57.951,2.554-72.604l0.008-0.341l-29.231-0.995l0.28-9.198c0.005-0.07-0.012-0.136-0.033-0.197h0.041
 l0.074-2.246l-5.319-0.181c0.144-3.274,0.292-7.075,0.445-11.065c0.357-9.239,0.765-19.708,1.213-26.911
 c2.982-47.816,8.779-69.572,12.139-78.535l6.96,0.197h0.016c0.185,0,0.358-0.091,0.473-0.251c0.135-0.181,0.168-0.419,0.094-0.641
 c-0.317-0.901-0.296-2.699-0.296-2.715c0-0.016,0.008-0.255,0.016-0.658l4.484,0.053c7.907,0.263,18.754,0.601,32.011,0.884
 l-0.001,0.009c0.016-0.002,0.03-0.009,0.044-0.009c0.101,0,0.201-0.007,0.302-0.009c0.135-0.004,0.264,0.008,0.399,0.028
 c6.445,0.135,13.448,0.262,20.959,0.361v2.361c0,0.777,0.633,1.411,1.411,1.411h14.364c0.777,0,1.411-0.633,1.411-1.411v-2.197
 c0.354,0.004,0.827,0.004,1.288,0.008l8.338,0.07l0.144,28.062H367.434z M360.198,81.08l-1.242-0.004
 c-0.461-0.004-0.933-0.004-1.288-0.008V60.607c6.121-0.016,12.468-0.041,19.013-0.078v20.662c-0.942,0.004-1.884,0.004-2.81,0.008
 L360.198,81.08z M375.032,240.503c-0.103-0.387-0.185-2.308-0.284-4.739c-0.411-9.897-1.271-30.493-6.565-32.785
 c-0.025-0.008-0.045-0.012-0.07-0.021v-5.701c1.399-0.337,3.587,0.243,5.406,1.452c1.094,0.728,2.965,2.304,3.221,4.669
 c0.382,3.55-0.852,30.238-1.313,39.839C375.262,241.811,375.135,240.869,375.032,240.503 M476.849,322.317l-0.255,0.042
 c-0.49,0.074-49.457,7.511-93.34-0.079l-0.004-0.14c-0.313-2.176-0.391-7.12-0.482-12.842c-0.177-10.963-0.415-25.977-2.456-33.53
 c-2.172-8.046-3.62-21.16-4.488-28.996c-0.004-0.033-0.004-0.066-0.008-0.099l0.119,0.004c0.082-1.588,1.949-39,1.48-43.373
 c-0.21-1.958-1.46-3.789-3.521-5.158c-1.93-1.283-4.196-1.896-5.784-1.588v-86.643h0.009l-0.148-28.087l5.899,0.049
 c0.926,0,1.868-0.004,2.81-0.008v2.073c0,0.777,0.633,1.411,1.411,1.411h14.364c0.777,0,1.411-0.633,1.411-1.411v-2.217
 c8.223-0.107,16.54-0.255,24.784-0.448v0.002c0.043-0.013,0.086-0.026,0.132-0.027c0.183-0.004,0.367,0.004,0.551,0.004
 c0.007,0,0.013,0.004,0.019,0.004c10.477-0.251,20.827-0.563,30.7-0.942l3.143-0.062v0.399c0,0.016-0.012,1.678-0.3,2.636
 c-0.074,0.247-0.029,0.506,0.127,0.716c0.152,0.21,0.404,0.333,0.662,0.333h6.771l0.033-0.033
 c2.888,7.507,9.276,29.107,12.357,78.56c0.757,12.123,1.37,25.113,1.868,38.256h-8.153v2.25h0.041
 c-0.028,0.058-0.045,0.128-0.045,0.193l-0.029,9.206h-29.251l-0.005,0.341c-0.107,14.656,0.012,50.966,0.086,72.657
 c0.029,8.285,0.049,14.829,0.049,17.392v0.346l39.379-0.169C476.854,313.889,476.849,320.812,476.849,322.317"
      />
      <path
        fill="#010101"
        d="M461.741,182.754l-2.443-51.036c0.021-0.021,0.045-0.041,0.062-0.058l0.3,0.132l-1.181-24.883l-0.078-1.715
 l-37.346,1.656c-0.005,0-0.009,0.003-0.013,0.003c-0.134,0-0.267,0.012-0.399,0.029l0.122-0.016
 c-0.132,0.018-0.255,0.037-0.386,0.021c-0.013-0.002-0.025-0.005-0.038-0.006l-39.987,1.773l-0.346,0.012l0.074,1.641l1.173,24.677
 l0.004,0.132l0.095,0.09c0.012,0.016,0.226,0.197,0.547,0.469l2.431,50.691l0.009,0.07c0.029,0.107,8.457,9.445,8.589,9.515
 l0.082,0.041l60.966-2.867l0.066-0.169c0.226-0.309,2.143-2.805,3.838-5.018C461.761,182.873,461.761,182.873,461.741,182.754
  M380.729,109.323l77.018-3.414l0.033,0.769l-77.009,3.595L380.729,109.323z M382.527,135.304c-0.276-0.234-0.481-0.407-0.593-0.502
 l-1.135-23.842l77.014-3.595l1.131,23.772c-0.44,0.387-1.386,1.374-3.694,3.789c-1.584,1.654-3.254,3.406-3.677,3.854l-61.53,2.855
 c-0.745-0.613-5.603-4.714-7.47-6.294V135.3L382.527,135.304z M457.335,187.517c-2.653,3.468-3.496,4.566-3.772,4.936l-60.329,2.842
 c-0.926-1.008-7.462-8.227-8.223-9.083l-2.395-49.934c1.23,1.041,2.912,2.46,2.942,2.485c1.036,0.872,2.073,1.744,2.85,2.398
 c1.111,0.934,1.395,1.172,1.559,1.172c0.021,0,0.042-0.004,0.062-0.012l61.711-2.863l0.14-0.008l0.095-0.103
 c0.358-0.378,4.768-5.002,6.668-6.96l2.406,50.275C460.688,183.149,458.915,185.456,457.335,187.517"
      />
      <path
        fill="#010101"
        d="M156.412,85.93c-1.16,0-1.785,1.95-1.785,3.784c0,1.835,0.625,3.784,1.785,3.784
 c1.16,0,1.785-1.95,1.785-3.784C158.197,87.88,157.572,85.93,156.412,85.93z M156.387,92.808c0.001,0,0.002,0,0.003,0
 c0.023,0,0.045-0.006,0.068-0.007c-0.015,0.003-0.031,0.014-0.045,0.014C156.404,92.816,156.395,92.809,156.387,92.808z"
      />
    </svg>
  );
}
